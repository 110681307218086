var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", width: "100%" },
          model: {
            value: _vm.addDialog,
            callback: function ($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4 pa-lg-8" },
            [
              _c("mew-address-select", {
                attrs: { label: "Address", items: _vm.addresses },
              }),
              _c("mew-input", {
                attrs: { label: "Address name", placeholder: " " },
              }),
              _c("mew-button", {
                staticClass: "mt-1 mx-auto display--block",
                attrs: { title: "Add", "btn-size": "xlarge" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-block d-sm-flex justify-space-between mb-7" },
        [
          _c("app-block-title", {
            attrs: {
              "max-width": "600px",
              "no-page-title": "",
              data: _vm.title,
            },
          }),
          _c("mew-button", {
            staticClass: "px-0",
            attrs: {
              "icon-align": "left",
              title: "Add More",
              "btn-style": "transparent",
              icon: "mdi-plus-circle",
              "icon-type": "mdi",
              "btn-size": "small",
            },
            on: {
              click: function ($event) {
                _vm.addDialog = true
              },
            },
          }),
        ],
        1
      ),
      _c("tools-table-container"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }